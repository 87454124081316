import React, { Component } from "react";
import { Link, navigate } from "gatsby";

import Layout from "../layouts/en";
import SEO from "../components/seo";
import NewPassword from "../services/NewPassword";
import NewPasswordLang from "../data/static-pages/pages/new-password/en";
import { getUserToken } from "../services/Auth";

export default class NewPasswordPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.urlLogin = "/login";
    this.urlRedirect = "/member";
  }

  componentDidMount() {

    this.checkUser()
  }

  checkUser() {

    if (getUserToken() == null) navigate('/login');
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Create New Password " keyword="Create New Password" decription="Create New Password" />

        <NewPassword
          props={this.props}
          urlRedirect={this.urlRedirect}
          langData={NewPasswordLang}
          urlLogin={this.urlLogin}
        />
      </Layout>
    );
  }
}
