import React, { useEffect } from "react";
import { Link, navigate } from "gatsby";
import { Form, Button, Input, Checkbox } from "antd";
import { FormInstance } from "antd/lib/form";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export default function FormNewPassword({
  handleSubmit,
  handleUpdate,
  subscribe,
  props,
  state,
}) {
  const [form] = Form.useForm();
  const validateMessages = {
    required: "${name} is required!",
  };
  const { loadings } = state;

  return (
    <>
      <div className="login" style={{ maxWidth: "480px" }}>
        <h1 className="text--center">{props.langData.title}</h1>

        <p className="p--sm text--center mart--xxl marb--xxl">
          {props.langData.subtitle_1} <br />
          {props.langData.subtitle_2} <br />
          {props.langData.subtitle_3}
        </p>

        <Form
          className="mart--xl"
          form={form}
          name="new_password"
          size="large"
          validateMessages={validateMessages}
          onFinish={handleSubmit}
          initialValues={{ subscribe: true }}
          scrollToFirstError>
          <div className="form__row">
            <div className="col col--100">
              <Form.Item
                name="password"
                rules={[{ required: true, min: 6, max: 25 }]}>
                <Input.Password
                  placeholder={props.langData.placeholder_password}
                  className="input"
                  onChange={handleUpdate}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="form__row flex">
            <div className="form__col form__col--inline">
              <Form.Item name="subscribe" defaultChecked={true}
                valuePropName="checked">
                <Checkbox checked={true}>
                  {props.langData.placeholder_subscribe}
                </Checkbox>
              </Form.Item>
            </div>
          </div>

          <div className="form__row text--center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loadings[0]}
              size="large"
              block={true}>
              {props.langData.btn_finish}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
