import React, { Component, useContext, useState, useEffect } from "react";
import FormNewPassword from "../components/FormNewPassword";
import axios from "axios";
import { navigate } from "gatsby";
import { isLoggedIn, getUserToken, setAuthTokenState } from "./Auth";
import { instance } from "./Axios";
import { guestInfo } from "./Guest";
import { headerConfig, headerConfigWithAuthorize } from "./Header";
import { loadState, saveState, clearAllState } from "state/utils/localStorage";

class NewPassword extends React.Component {
  dateNow = new Date().getTime();
  state = {
    password: "",
    subscribe: true,
    loadings: [],
    confirmLoading: false,
  };

  subscribe() {
    this.setState({
      subscribe: !this.state.subscribe,
    });
  }

  showLoading() {
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[0] = true;

      return {
        loadings: newLoadings,
      };
    });
  }

  hideLoading() {
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[0] = false;

      return {
        loadings: newLoadings,
      };
    });
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(params) {
    try {
      // instance()
      let self = this;
      self.showLoading();
      let token = getUserToken();
      let header = headerConfig();
      const bodyFormData = new FormData();
      bodyFormData.append("password", params.password);
      bodyFormData.append("subscribe", params.subscribe == true ? 1 : 0);
      bodyFormData.append("token", token);
      bodyFormData.append("source", "website");

      if (token) {
        (async () => {
          await axios
            .post(
              `${process.env.GATSBY_API_URL}/api/guest/complete-activation`,
              bodyFormData,
              header
            )
            .then(function (response) {
              if (response.data.status_code == 200100) {
                (async () => {
                  if (await guestInfo(response.data.data.token)) {
                    saveState("token", "");
                    saveState("setupTime", self.dateNow);
                    setAuthTokenState(response.data.data.token);
                    navigate(self.props.urlRedirect);
                  } else {
                    alert("Failed get guest info");
                    navigate(self.props.urlLogin);
                  }
                })();
              } else {
                alert(response.data.message);
                navigate(self.props.urlLogin);
              }
            })
            .catch(function (error) {
              if (error.response) {
                console.log(error.response);
                self.hideLoading();
              } else if (error.request) {
                console.log(error.request);
                self.hideLoading();
              } else {
                console.log("Error", error.message);
                self.hideLoading();
              }
            });
        })();
      } else navigate(self.props.urlLogin);
    } catch (error) {
      console.log(error);
      this.hideLoading();
    }
  }

  render() {
    return (
      <FormNewPassword
        state={this.state}
        props={this.props}
        subscribe={(e) => this.subscribe(e)}
        handleUpdate={(e) => this.handleUpdate(e)}
        handleSubmit={(e) => this.handleSubmit(e)}
      />
    );
  }
}
export default NewPassword;
